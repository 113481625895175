/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateAddressDto,
    CreateAddressDtoFromJSON,
    CreateAddressDtoFromJSONTyped,
    CreateAddressDtoToJSON,
    CreateBankAccountDto,
    CreateBankAccountDtoFromJSON,
    CreateBankAccountDtoFromJSONTyped,
    CreateBankAccountDtoToJSON,
    ProtocolCustomizations,
    ProtocolCustomizationsFromJSON,
    ProtocolCustomizationsFromJSONTyped,
    ProtocolCustomizationsToJSON,
    ReportCustomization,
    ReportCustomizationFromJSON,
    ReportCustomizationFromJSONTyped,
    ReportCustomizationToJSON,
    SellerCheckouts,
    SellerCheckoutsFromJSON,
    SellerCheckoutsFromJSONTyped,
    SellerCheckoutsToJSON,
    SellerEmails,
    SellerEmailsFromJSON,
    SellerEmailsFromJSONTyped,
    SellerEmailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface EditSellerPartialDto
 */
export interface EditSellerPartialDto {
    /**
     * Nome
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    name?: string;
    /**
     * Descrição
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    description?: string;
    /**
     * Documento
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    document?: string;
    /**
     * Registro estadual
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    state_registration?: string;
    /**
     * Chave do Estabelecimento
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    code?: string;
    /**
     * Tipo de liquidação
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    settlement_type?: EditSellerPartialDtoSettlementTypeEnum;
    /**
     * Endereço
     * @type {CreateAddressDto}
     * @memberof EditSellerPartialDto
     */
    address?: CreateAddressDto;
    /**
     * Conta bancária
     * @type {CreateBankAccountDto}
     * @memberof EditSellerPartialDto
     */
    bank_account?: CreateBankAccountDto;
    /**
     * Faturamento Mensal
     * @type {number}
     * @memberof EditSellerPartialDto
     */
    monthly_billing?: number;
    /**
     * Convênio
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    covenant?: string;
    /**
     * Tem divisão de pagamento
     * @type {boolean}
     * @memberof EditSellerPartialDto
     */
    is_split?: boolean;
    /**
     * Telefone
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    phone?: string;
    /**
     * Absorver Custos
     * @type {Array<string>}
     * @memberof EditSellerPartialDto
     */
    absorb_costs?: Array<string>;
    /**
     * Há PIX POS
     * @type {boolean}
     * @memberof EditSellerPartialDto
     */
    pix_pos?: boolean;
    /**
     * Pagamentos não utilizados
     * @type {Array<string>}
     * @memberof EditSellerPartialDto
     */
    no_payment_methods?: Array<string>;
    /**
     * Há divisão de pagamento
     * @type {boolean}
     * @memberof EditSellerPartialDto
     */
    has_split_rules?: boolean;
    /**
     * Customização de protocolos
     * @type {ProtocolCustomizations}
     * @memberof EditSellerPartialDto
     */
    protocol_customizations?: ProtocolCustomizations;
    /**
     * Customização de emails
     * @type {SellerEmails}
     * @memberof EditSellerPartialDto
     */
    custom_emails?: SellerEmails;
    /**
     * Customização de checkouts
     * @type {SellerCheckouts}
     * @memberof EditSellerPartialDto
     */
    custom_checkout?: SellerCheckouts;
    /**
     * Personalização de relatórios
     * @type {ReportCustomization}
     * @memberof EditSellerPartialDto
     */
    report_customization?: ReportCustomization;
    /**
     * Responsáveis pelo estabelecimento
     * @type {Array<string>}
     * @memberof EditSellerPartialDto
     */
    responsibles?: Array<string>;
    /**
     * Código do cartório de registro
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    cns?: string;
    /**
     * Classificação de atividades econômicas
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    cnae?: string;
    /**
     * Atribuição do cartório
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    assignment?: EditSellerPartialDtoAssignmentEnum;
    /**
     * Nome do proprietário
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    owner_name?: string;
    /**
     * Documento do proprietário
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    owner_document?: string;
    /**
     * Sistema de gestão utilizado
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    erp?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditSellerPartialDto
     */
    document_ofac?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditSellerPartialDto
     */
    document_onu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditSellerPartialDto
     */
    document_rf?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    status?: EditSellerPartialDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    type?: EditSellerPartialDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    merchant_id?: string;
    /**
     * 
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    sale_plan_id?: string;
    /**
     * 
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    billet_provider?: EditSellerPartialDtoBilletProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    pix_provider?: EditSellerPartialDtoPixProviderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EditSellerPartialDto
     */
    owner_document_ofac?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditSellerPartialDto
     */
    owner_document_onu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditSellerPartialDto
     */
    owner_document_pep?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditSellerPartialDto
     */
    owner_document_ref?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EditSellerPartialDto
     */
    owner_birth?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EditSellerPartialDto
     */
    frontier?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditSellerPartialDto
     */
    extract_name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditSellerPartialDto
     */
    registered_omie?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum EditSellerPartialDtoSettlementTypeEnum {
    Pix = 'PIX',
    Ted = 'TED'
}/**
* @export
* @enum {string}
*/
export enum EditSellerPartialDtoAssignmentEnum {
    LegalEntity = 'legal_entity',
    Civil = 'civil',
    Protest = 'protest',
    Note = 'note',
    Properties = 'properties'
}/**
* @export
* @enum {string}
*/
export enum EditSellerPartialDtoStatusEnum {
    Activated = 'activated',
    Inactive = 'inactive',
    Pending = 'pending',
    ActivationSent = 'activation_sent'
}/**
* @export
* @enum {string}
*/
export enum EditSellerPartialDtoTypeEnum {
    Transaction = 'transaction',
    Registry = 'registry',
    Group = 'group',
    RestrictedSeller = 'restricted_seller',
    Creditor = 'creditor'
}/**
* @export
* @enum {string}
*/
export enum EditSellerPartialDtoBilletProviderEnum {
    Adyen = 'adyen',
    Bradesco = 'bradesco',
    Itau = 'itau',
    Btg = 'btg',
    BtgDigital = 'btg_digital'
}/**
* @export
* @enum {string}
*/
export enum EditSellerPartialDtoPixProviderEnum {
    Adyen = 'adyen',
    Bradesco = 'bradesco',
    Itau = 'itau',
    BtgDigital = 'btg_digital'
}

export function EditSellerPartialDtoFromJSON(json: any): EditSellerPartialDto {
    return EditSellerPartialDtoFromJSONTyped(json, false);
}

export function EditSellerPartialDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditSellerPartialDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'document': !exists(json, 'document') ? undefined : json['document'],
        'state_registration': !exists(json, 'state_registration') ? undefined : json['state_registration'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'settlement_type': !exists(json, 'settlement_type') ? undefined : json['settlement_type'],
        'address': !exists(json, 'address') ? undefined : CreateAddressDtoFromJSON(json['address']),
        'bank_account': !exists(json, 'bank_account') ? undefined : CreateBankAccountDtoFromJSON(json['bank_account']),
        'monthly_billing': !exists(json, 'monthly_billing') ? undefined : json['monthly_billing'],
        'covenant': !exists(json, 'covenant') ? undefined : json['covenant'],
        'is_split': !exists(json, 'is_split') ? undefined : json['is_split'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'absorb_costs': !exists(json, 'absorb_costs') ? undefined : json['absorb_costs'],
        'pix_pos': !exists(json, 'pix_pos') ? undefined : json['pix_pos'],
        'no_payment_methods': !exists(json, 'no_payment_methods') ? undefined : json['no_payment_methods'],
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'protocol_customizations': !exists(json, 'protocol_customizations') ? undefined : ProtocolCustomizationsFromJSON(json['protocol_customizations']),
        'custom_emails': !exists(json, 'custom_emails') ? undefined : SellerEmailsFromJSON(json['custom_emails']),
        'custom_checkout': !exists(json, 'custom_checkout') ? undefined : SellerCheckoutsFromJSON(json['custom_checkout']),
        'report_customization': !exists(json, 'report_customization') ? undefined : ReportCustomizationFromJSON(json['report_customization']),
        'responsibles': !exists(json, 'responsibles') ? undefined : json['responsibles'],
        'cns': !exists(json, 'cns') ? undefined : json['cns'],
        'cnae': !exists(json, 'cnae') ? undefined : json['cnae'],
        'assignment': !exists(json, 'assignment') ? undefined : json['assignment'],
        'owner_name': !exists(json, 'owner_name') ? undefined : json['owner_name'],
        'owner_document': !exists(json, 'owner_document') ? undefined : json['owner_document'],
        'erp': !exists(json, 'erp') ? undefined : json['erp'],
        'document_ofac': !exists(json, 'document_ofac') ? undefined : json['document_ofac'],
        'document_onu': !exists(json, 'document_onu') ? undefined : json['document_onu'],
        'document_rf': !exists(json, 'document_rf') ? undefined : json['document_rf'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'merchant_id': !exists(json, 'merchant_id') ? undefined : json['merchant_id'],
        'sale_plan_id': !exists(json, 'sale_plan_id') ? undefined : json['sale_plan_id'],
        'billet_provider': !exists(json, 'billet_provider') ? undefined : json['billet_provider'],
        'pix_provider': !exists(json, 'pix_provider') ? undefined : json['pix_provider'],
        'owner_document_ofac': !exists(json, 'owner_document_ofac') ? undefined : json['owner_document_ofac'],
        'owner_document_onu': !exists(json, 'owner_document_onu') ? undefined : json['owner_document_onu'],
        'owner_document_pep': !exists(json, 'owner_document_pep') ? undefined : json['owner_document_pep'],
        'owner_document_ref': !exists(json, 'owner_document_ref') ? undefined : json['owner_document_ref'],
        'owner_birth': !exists(json, 'owner_birth') ? undefined : (new Date(json['owner_birth'])),
        'frontier': !exists(json, 'frontier') ? undefined : json['frontier'],
        'extract_name': !exists(json, 'extract_name') ? undefined : json['extract_name'],
        'registered_omie': !exists(json, 'registered_omie') ? undefined : json['registered_omie'],
    };
}

export function EditSellerPartialDtoToJSON(value?: EditSellerPartialDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'document': value.document,
        'state_registration': value.state_registration,
        'code': value.code,
        'settlement_type': value.settlement_type,
        'address': CreateAddressDtoToJSON(value.address),
        'bank_account': CreateBankAccountDtoToJSON(value.bank_account),
        'monthly_billing': value.monthly_billing,
        'covenant': value.covenant,
        'is_split': value.is_split,
        'phone': value.phone,
        'absorb_costs': value.absorb_costs,
        'pix_pos': value.pix_pos,
        'no_payment_methods': value.no_payment_methods,
        'has_split_rules': value.has_split_rules,
        'protocol_customizations': ProtocolCustomizationsToJSON(value.protocol_customizations),
        'custom_emails': SellerEmailsToJSON(value.custom_emails),
        'custom_checkout': SellerCheckoutsToJSON(value.custom_checkout),
        'report_customization': ReportCustomizationToJSON(value.report_customization),
        'responsibles': value.responsibles,
        'cns': value.cns,
        'cnae': value.cnae,
        'assignment': value.assignment,
        'owner_name': value.owner_name,
        'owner_document': value.owner_document,
        'erp': value.erp,
        'document_ofac': value.document_ofac,
        'document_onu': value.document_onu,
        'document_rf': value.document_rf,
        'created_at': value.created_at,
        'status': value.status,
        'type': value.type,
        'merchant_id': value.merchant_id,
        'sale_plan_id': value.sale_plan_id,
        'billet_provider': value.billet_provider,
        'pix_provider': value.pix_provider,
        'owner_document_ofac': value.owner_document_ofac,
        'owner_document_onu': value.owner_document_onu,
        'owner_document_pep': value.owner_document_pep,
        'owner_document_ref': value.owner_document_ref,
        'owner_birth': value.owner_birth === undefined ? undefined : (value.owner_birth.toISOString()),
        'frontier': value.frontier,
        'extract_name': value.extract_name,
        'registered_omie': value.registered_omie,
    };
}


