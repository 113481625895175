import { Flex, Image, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useToasts } from 'react-toast-notifications';

import { Formik } from 'formik';

import { PreRegisterSellerDto, PreRegisterSellerDtoAbsorbCostsEnum, SellersApi } from '../../clients';
import Button from '../../components/Button';
import Title from '../../components/Title';
import { getApiAuthConfig } from '../../services/api.service';
import BankAccountForm from './components/BankAccountForm';
import ConfirmInformation from './components/ConfirmInformation';
import CustomizationForm from './components/CustomizationForm';
import OwnerDataForm from './components/OwnerDataForm';
import SellerDataForm from './components/SellerDataForm';
import preRegisterSellerInitialValues from './validations/initialValues';
import preRegisterSellerSchema from './validations/schema';

const PreRegister: React.FC = () => {
	const { addToast } = useToasts();
	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const handleFormSubmit = async (values, isValid) => {
		if (!isValid) {
			return;
		}
		const {
			active_debit,
			active_credit_1x,
			active_credit_2x_to_18x,
			active_billet,
			active_pix_pos,
			active_pix_online,
			pass_on_cost_debit,
			pass_on_cost_credit_1x,
			pass_on_cost_credit_2x_to_18x,
			pass_on_cost_billet,
			pass_on_cost_pix_pos,
			pass_on_cost_pix_online,
			...parsedData
		} = values;

		setIsLoading(true);

		const preRegisterSellerDto: PreRegisterSellerDto = {
			active_debit: false,
			active_credit_1x: false,
			active_credit_2x_to_18x: false,
			active_billet: false,
			active_pix_pos: false,
			active_pix_online: false,
			...parsedData,
		};

		if (active_debit === 'true') {
			preRegisterSellerDto.active_debit = true;
		}
		if (active_credit_1x === 'true') {
			preRegisterSellerDto.active_debit = true;
		}
		if (active_credit_2x_to_18x === 'true') {
			preRegisterSellerDto.active_debit = true;
		}
		if (active_billet === 'true') {
			preRegisterSellerDto.active_debit = true;
		}
		if (active_pix_pos === 'true') {
			preRegisterSellerDto.active_debit = true;
		}
		if (active_pix_online === 'true') {
			preRegisterSellerDto.active_debit = true;
		}

		preRegisterSellerDto.absorb_costs = [];

		if (pass_on_cost_debit === 'false') {
			preRegisterSellerDto.absorb_costs.push(PreRegisterSellerDtoAbsorbCostsEnum.Debit);
		}

		if (pass_on_cost_credit_1x === 'false') {
			preRegisterSellerDto.absorb_costs.push(PreRegisterSellerDtoAbsorbCostsEnum.Credit1x);
		}

		if (pass_on_cost_credit_2x_to_18x === 'false') {
			preRegisterSellerDto.absorb_costs.push(
				PreRegisterSellerDtoAbsorbCostsEnum.Credit2x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit3x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit4x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit5x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit6x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit7x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit8x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit9x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit10x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit11x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit12x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit13x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit14x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit15x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit16x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit17x,
				PreRegisterSellerDtoAbsorbCostsEnum.Credit18x
			);
		}

		if (pass_on_cost_billet === 'false') {
			preRegisterSellerDto.absorb_costs.push(PreRegisterSellerDtoAbsorbCostsEnum.Billet);
		}

		if (pass_on_cost_pix_pos === 'false') {
			preRegisterSellerDto.absorb_costs.push(PreRegisterSellerDtoAbsorbCostsEnum.PixPos);
		}

		if (pass_on_cost_pix_online === 'false') {
			preRegisterSellerDto.absorb_costs.push(PreRegisterSellerDtoAbsorbCostsEnum.Pix);
		}

		try {
			await sellersApi.preRegisterSeller({ preRegisterSellerDto });
		} catch (error) {
			addToast('Erro ao realizar cadastro', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Flex direction={['column', 'row']} height='100%' justifyContent='space-between'>
			<Flex maxH='100%' overflow='scroll' direction='column' alignItems='center' padding='30px' width='100%'>
				<Flex direction='column' width='100%' alignItems='center'>
					<Title id='pre-register-title-1' textAlign='center' fontSize='xl'>
						TERMO DE CREDENCIAMENTO CARTÓRIOS
					</Title>
					<Title id='pre-register-title-2' textAlign='center' fontSize='xl'>
						EMRESAS PARCELA EXPRESS
					</Title>
				</Flex>
				<Flex>
					<Formik validateOnMount initialValues={preRegisterSellerInitialValues} onSubmit={handleFormSubmit} validationSchema={preRegisterSellerSchema}>
						{({ handleSubmit, setFieldValue, values, isValid }) => (
							<form onSubmit={handleSubmit}>
								<SellerDataForm setFieldValue={setFieldValue} />
								<OwnerDataForm setFieldValue={setFieldValue} values={values} />
								<BankAccountForm />
								<CustomizationForm values={values} />
								<ConfirmInformation />
								<Flex>
									<Button id='pre-regiter-submit-button' type='submit' isLoading={isLoading} disabled={!isValid} w='100%'>
										Enviar
									</Button>
								</Flex>
							</form>
						)}
					</Formik>
				</Flex>
			</Flex>
			{!isMobile && (
				<Flex width='100%' justifyContent='flex-end'>
					<Image maxH='100%' src={require('../../assets/images/image-login.png')} />
				</Flex>
			)}
		</Flex>
	);
};

export default PreRegister;
