import React from 'react';
import { Box, BreadcrumbItem, BreadcrumbLink, Checkbox, CheckboxGroup, Flex, Stack } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';
import { getApiAuthConfig } from 'services/api.service';
import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';
import { DigitalAccountApi, DigitalAuthenticationRequest, DigitalAuthorizationRequest, SellersApi } from '../../clients';
import { useLocation } from 'react-router-dom';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import Breadcrumb from 'components/Breadcrumb';
import Text from 'components/Text';
import Title from 'components/Title';
import Loader from 'containers/Loader';
import Button from '../../components/Button';

const DigitalAccount: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const digitalAccountApi = new DigitalAccountApi(apiConfig);
	const { addToast } = useToasts();
	const location = useLocation();
	const { currentSeller } = useCurrentSeller();
	const [hasPermissions, setHasPermissions] = React.useState<boolean>(false);
	const [scopes, setScopes] = React.useState<Array<string>>([]);
	const [sellersAccounts, setSellersAccounts] = React.useState<any>([]);
	const [selectedSellers, setSelectedSellers] = React.useState<Array<string>>([]);

	const handleAuthorization = async () => {
		setIsLoading(true);
		try {
			const createDigitalAuthRequest: DigitalAuthorizationRequest = {
				createDigitalAuthorizationDto: {
					uri_path: location.pathname,
				},
			};
			const response = await digitalAccountApi.digitalAuthorization(createDigitalAuthRequest);
			setIsLoading(false);
			if (response.url) {
				window.location.href = response.url;
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const getSellersAccount = async () => {
		if (currentSeller) {
			const response = await sellersApi.listSellers({
				limit: 100,
				currentPage: 1,
				document: currentSeller.document,
			});
			setSellersAccounts(response.results);
		}
	};

	const digitalAuthentication = async () => {
		const params = new URLSearchParams(location.search);
		const authorizationCode = params.get('code');
		if (authorizationCode && currentSeller) {
			setIsLoading(true);
			try {
				const createDigitalAuthRequest: DigitalAuthenticationRequest = {
					createDigitalAuthenticationDto: {
						document: currentSeller.document,
						sellersIds: selectedSellers,
						authorization_code: authorizationCode,
						uri_path: location.pathname,
					},
				};
				await digitalAccountApi.digitalAuthentication(createDigitalAuthRequest);
				const response = await digitalAccountApi.getDigitalCredentials({
					document: currentSeller.document,
				});
				setHasPermissions(response.has_credentials);
				if (response?.has_credentials && response?.scope) {
					setScopes(response.scope);
				}
				addToast('Permissões concedidas!', {
					appearance: 'success',
					autoDismiss: true,
				});
			} catch (error) {
				console.error(error);
				addToast('Ocorreu um erro ao conceder permissões na sua conta digital. Por favor, tente novamente.', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				const newUrl = new URL(window.location.href);
				newUrl.search = '';
				window.history.replaceState({}, document.title, newUrl.toString());
				setIsLoading(false);
			}
		}
	};

	const permissionsEnum = (permission: string) => {
		const permissions = {
			'empresas.btgpactual.com/accounts.readonly': 'Banking: Saldo e Extrato.',
			'empresas.btgpactual.com/bank-slips': 'Banking: Boleto Cobrança.',
			'empresas.btgpactual.com/pix-cash-in': 'Banking: Pix Cobrança.',
			openid: 'Perfil do usuário.',
		};
		return permissions[permission];
	};

	const handlePermissionButton = () => {
		handleAuthorization();
	};

	const handleDigitalAuthentication = async () => {
		await digitalAuthentication();
	};

	const handleRemoveDigitalAccount = async () => {
		if (currentSeller) {
			setIsLoading(true);
			try {
				await digitalAccountApi.removeDigitalCredentials({
					document: currentSeller.document,
				});
				setHasPermissions(false);
				setScopes([]);
				addToast('Permissões removidas!', {
					appearance: 'success',
					autoDismiss: true,
				});
			} catch (error) {
				console.error(error);
				addToast('Ocorreu um erro ao remover permissões na sua conta digital. Por favor, tente novamente.', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	};

	React.useEffect(() => {
		(async function () {
			if (!currentSeller) {
				return;
			}
			const params = new URLSearchParams(location.search);
			const authorizationCode = params.get('code');
			if (authorizationCode) {
				return getSellersAccount();
			}
			try {
				setIsLoading(true);
				const response = await digitalAccountApi.getDigitalCredentials({
					document: currentSeller.document,
				});
				setHasPermissions(response.has_credentials);
				if (response?.has_credentials && response?.scope) {
					setScopes(response.scope);
				}
			} catch (err) {
				console.error(err);
			} finally {
				setIsLoading(false);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller]);

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<>
			<Loader isOpen={isLoading} />
			<Flex pb='8'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Conta Digital
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Flex mb='4'>
				<Title id='my-data-title-text' fontSize={`2xl`}>
					CONTA DIGITAL
				</Title>
			</Flex>

			{!isLoading && !hasPermissions && !sellersAccounts.length && (
				<>
					<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
						<Text id='waning-permissions-text' fontSize={`md`} fontWeight={`bold`}>
							Atenção!
						</Text>
						<Text id='permissions-text' fontSize={`md`} fontWeight={`medium`}>
							Para emitir pagamentos diretamente da sua conta digital, é necessário realizar o procedimento de autorização.
						</Text>
						<Text id='grant-permissions-text' fontSize={`md`} fontWeight={`medium`}>
							Ao continuar, você será redirecionado para a página do seu banco. Lá, será necessário conceder as permissões de acesso à sua
							conta digital.
						</Text>
						<Box mt='4'>
							<Button id='account-permissions-button' size='md' type='button' bgColor='primary' onClick={() => handlePermissionButton()}>
								Conceder Permissões
							</Button>
						</Box>
					</Flex>
				</>
			)}

			{!hasPermissions && sellersAccounts.length > 0 && (
				<>
					<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
						<Text id='permissions-disclaimer-text' fontSize={`md`} fontWeight={`semibold`} mb='2'>
							Selecione os estabelecimentos que utilizarão a conta digital:
						</Text>
						<Stack isInline spacing={4}>
							<Stack spacing={3}>
								<CheckboxGroup
									colorScheme='green'
									value={selectedSellers.map(String)}
									onChange={(value: string[]) => setSelectedSellers(value)}
								>
									<Stack spacing={4}>
										{sellersAccounts.map((seller) => (
											<Checkbox key={seller.id} id={`checkbox-seller${seller.id}`} value={seller.id}>
												{seller.description}
											</Checkbox>
										))}
									</Stack>
								</CheckboxGroup>
							</Stack>
						</Stack>
						<Box mt='4'>
							<Button
								id='account-permissions-button'
								size='md'
								type='button'
								bgColor='primary'
								onClick={() => handleDigitalAuthentication()}
							>
								Confirmar
							</Button>
						</Box>
					</Flex>
				</>
			)}

			{hasPermissions && (
				<>
					<Flex mt='8'>
						<Title id='permissions-title-text'>Permissões Concedidas</Title>
					</Flex>
					<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
						<Text id='permissions-disclaimer-text' fontSize={`md`} fontWeight={`semibold`} mb='2'>
							As seguintes permissões foram autorizadas e estão habilitadas para uso:
						</Text>
						{scopes.map((scope) => (
							<Text id='seller-account-permissions' fontSize={`md`} fontWeight={`medium`}>
								- {permissionsEnum(scope)}
							</Text>
						))}
						<Flex mt='4'>
							<Button
								id='remove-account-permissions-button'
								size='md'
								type='button'
								bgColor='error'
								onClick={() => handleRemoveDigitalAccount()}
							>
								Remover Permissões
							</Button>
						</Flex>
					</Flex>
				</>
			)}
		</>
	);
};

export default DigitalAccount;
