import { Flex, Link } from '@chakra-ui/react';
import React from 'react';

import Checkbox from '../../../components/Checkbox';
import Text from '../../../components/Text';
import TermsModal from './TermsModal';

// import { Container } from './styles';

const ConfirmInformation: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <Flex justifyContent='center' my='5'>
            <Checkbox id='pre-register-confirmation-button' name='confirm_information' mr='2' />
            <Text id='pre-register-terms-text'>Li e Aceito os <Link color='#337ab7' onClick={() => setIsOpen(true)}>Termos e Condições</Link></Text>
            <TermsModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </Flex>
    );
}

export default ConfirmInformation;
