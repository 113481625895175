/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateAddressDto,
    CreateAddressDtoFromJSON,
    CreateAddressDtoFromJSONTyped,
    CreateAddressDtoToJSON,
    CreateBankAccountDto,
    CreateBankAccountDtoFromJSON,
    CreateBankAccountDtoFromJSONTyped,
    CreateBankAccountDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PreRegisterSellerDto
 */
export interface PreRegisterSellerDto {
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    cns: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    document: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    description: string;
    /**
     * 
     * @type {CreateAddressDto}
     * @memberof PreRegisterSellerDto
     */
    address: CreateAddressDto;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    secondary_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    secondary_email?: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    erp?: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    seller_assignment: PreRegisterSellerDtoSellerAssignmentEnum;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    owner_name: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    owner_document: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    owner_mother_name: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegisterSellerDto
     */
    owner_birth: string;
    /**
     * 
     * @type {CreateBankAccountDto}
     * @memberof PreRegisterSellerDto
     */
    bank_account: CreateBankAccountDto;
    /**
     * 
     * @type {boolean}
     * @memberof PreRegisterSellerDto
     */
    active_debit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreRegisterSellerDto
     */
    active_credit_1x: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreRegisterSellerDto
     */
    active_credit_2x_to_18x: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreRegisterSellerDto
     */
    active_billet: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreRegisterSellerDto
     */
    active_pix_pos: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreRegisterSellerDto
     */
    active_pix_online: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreRegisterSellerDto
     */
    absorb_costs?: Array<PreRegisterSellerDtoAbsorbCostsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum PreRegisterSellerDtoSellerAssignmentEnum {
    LegalEntity = 'legal_entity',
    Civil = 'civil',
    Protest = 'protest',
    Note = 'note',
    Properties = 'properties'
}/**
* @export
* @enum {string}
*/
export enum PreRegisterSellerDtoAbsorbCostsEnum {
    Credit1x = 'credit1x',
    Credit2x = 'credit2x',
    Credit3x = 'credit3x',
    Credit4x = 'credit4x',
    Credit5x = 'credit5x',
    Credit6x = 'credit6x',
    Credit7x = 'credit7x',
    Credit8x = 'credit8x',
    Credit9x = 'credit9x',
    Credit10x = 'credit10x',
    Credit11x = 'credit11x',
    Credit12x = 'credit12x',
    Credit13x = 'credit13x',
    Credit14x = 'credit14x',
    Credit15x = 'credit15x',
    Credit16x = 'credit16x',
    Credit17x = 'credit17x',
    Credit18x = 'credit18x',
    Debit = 'debit',
    Pix = 'pix',
    Billet = 'billet',
    Pos = 'pos',
    PixPos = 'pix_pos'
}

export function PreRegisterSellerDtoFromJSON(json: any): PreRegisterSellerDto {
    return PreRegisterSellerDtoFromJSONTyped(json, false);
}

export function PreRegisterSellerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreRegisterSellerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'cns': json['cns'],
        'document': json['document'],
        'description': json['description'],
        'address': CreateAddressDtoFromJSON(json['address']),
        'phone': json['phone'],
        'secondary_phone': !exists(json, 'secondary_phone') ? undefined : json['secondary_phone'],
        'email': json['email'],
        'secondary_email': !exists(json, 'secondary_email') ? undefined : json['secondary_email'],
        'erp': !exists(json, 'erp') ? undefined : json['erp'],
        'seller_assignment': json['seller_assignment'],
        'owner_name': json['owner_name'],
        'owner_document': json['owner_document'],
        'owner_mother_name': json['owner_mother_name'],
        'owner_birth': json['owner_birth'],
        'bank_account': CreateBankAccountDtoFromJSON(json['bank_account']),
        'active_debit': json['active_debit'],
        'active_credit_1x': json['active_credit_1x'],
        'active_credit_2x_to_18x': json['active_credit_2x_to_18x'],
        'active_billet': json['active_billet'],
        'active_pix_pos': json['active_pix_pos'],
        'active_pix_online': json['active_pix_online'],
        'absorb_costs': !exists(json, 'absorb_costs') ? undefined : json['absorb_costs'],
    };
}

export function PreRegisterSellerDtoToJSON(value?: PreRegisterSellerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'cns': value.cns,
        'document': value.document,
        'description': value.description,
        'address': CreateAddressDtoToJSON(value.address),
        'phone': value.phone,
        'secondary_phone': value.secondary_phone,
        'email': value.email,
        'secondary_email': value.secondary_email,
        'erp': value.erp,
        'seller_assignment': value.seller_assignment,
        'owner_name': value.owner_name,
        'owner_document': value.owner_document,
        'owner_mother_name': value.owner_mother_name,
        'owner_birth': value.owner_birth,
        'bank_account': CreateBankAccountDtoToJSON(value.bank_account),
        'active_debit': value.active_debit,
        'active_credit_1x': value.active_credit_1x,
        'active_credit_2x_to_18x': value.active_credit_2x_to_18x,
        'active_billet': value.active_billet,
        'active_pix_pos': value.active_pix_pos,
        'active_pix_online': value.active_pix_online,
        'absorb_costs': value.absorb_costs,
    };
}


